/*===GIST===
============*/
body .gist .blob-code-inner {
    font-family: 'Poppins', 'Droid Sans Mono', monospace;
    font-size: 14px;
}

body .gist .gist-file {
    margin-bottom: 0;
    border: 1px solid;
    border-color: #ddd #ddd #ddd;
    border-radius: 3px;
}

body .gist .gist-data {
    border-bottom: none;
    border-radius: 1;
    background-color: #fff;
}

body .gist .blob-wrapper {
    border-radius: 1;
}

body .gist .highlight {
    background-color: transparent;
    font-family: 'Poppins', 'Droid Sans Mono', monospace;
    font-size: 14px;
}

body .gist .highlight td {
    padding: 5px 15px !important;
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
}

body .gist tr:first-child td {
    padding-top: 15px !important;
}

body .gist tr:last-child td {
    padding-bottom: 15px !important;
}

body .gist .blob-num {
    color: #ced4da;
    background-color: #fff;
    pointer-events: none;
    background: #fff;
}

body .gist .gist-meta {
    display: none;
}

.gist-container {
    position: relative;
    margin: 1em 0;
  }
  
  .gist-copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    padding: 5px 10px;
    background-color: #f6f8fa;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .gist-copy-button:hover {
    background-color: #e1e4e8;
  }
  
  .gist-copy-button svg {
    width: 14px;
    height: 14px;
  }